import { TabList, Tabs, IconNavTasks } from '@repo/design-system-kit';
import type { Key, ReactElement } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { NavRequests } from 'qonto/react/assets/icons';
import { INSIGHT_LABELS, TABS } from 'qonto/constants/supplier-invoice.ts';
import styles from './tabs.strict-module.css';

type IconKey = 'icon_nav_tasks' | 'icon_nav_requests';
type Insights = Record<(typeof INSIGHT_LABELS)[keyof typeof INSIGHT_LABELS], number>;

interface SupplierInvoicesTabsProps {
  activeId: string;
  enableAllTabs?: boolean;
  insights: Insights;
  totalInvoices: number;
  onTabChange: (tab: Key) => void;
}

interface TabsProps {
  id: string;
  index: number;
  icon?: IconKey;
  title: string;
  tab: string;
  count?: number;
  hasNotifications?: boolean;
  hasDivider?: boolean;
  event?: string;
}

const TAB_ICONS: Record<IconKey, ReactElement> = {
  icon_nav_tasks: <IconNavTasks data-testid="icon-nav-tasks" />,
  icon_nav_requests: <NavRequests data-testid="icon-nav-requests" />,
};

export function SupplierInvoicesTabs({
  activeId,
  enableAllTabs,
  insights,
  totalInvoices,
  onTabChange,
}: SupplierInvoicesTabsProps): JSX.Element {
  const abilities = useEmberService('abilities');
  const router = useEmberService('router');
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();

  const canReadRequest = abilities.can('read request');
  const canReviewTransferRequest = abilities.can('review transfer request');

  const tasksTab =
    canReadRequest || enableAllTabs
      ? {
          id: TABS.TASKS,
          index: 0,
          icon: canReviewTransferRequest || enableAllTabs ? 'icon_nav_tasks' : 'icon_nav_requests',
          title:
            canReviewTransferRequest || enableAllTabs
              ? formatMessage({
                  id: 'supplier-invoices.section.tabs.tasks.title',
                })
              : formatMessage({ id: 'supplier-invoices.section.tabs.requests.title' }),
          tab: TABS.TASKS,
          hasNotifications: Boolean(insights[INSIGHT_LABELS.pendingCounter]),
          hasDivider: true,
        }
      : null;

  const baseTabs = [
    {
      id: TABS.ALL_INVOICES,
      index: 1,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.all-invoices.title' }),
      count: totalInvoices,
      tab: TABS.ALL_INVOICES,
      event: 'supplier-invoices_all-invoices-tab_clicked',
    },
    {
      id: TABS.TO_PAY,
      index: 2,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.imported.title' }),
      count: insights[INSIGHT_LABELS.inboxCounter],
      tab: TABS.TO_PAY,
      event: 'supplier-invoices_imported-tab_clicked',
    },
    {
      id: TABS.SCHEDULED,
      index: 3,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.processing.title' }),
      count: insights[INSIGHT_LABELS.scheduledCounter],
      tab: TABS.SCHEDULED,
      event: 'supplier-invoices_processing-tab_clicked',
    },
    {
      id: TABS.COMPLETED,
      index: 4,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.completed.title' }),
      tab: TABS.COMPLETED,
      event: 'supplier-invoices_paid-tab_clicked',
    },
  ];

  const tabs = [tasksTab, ...baseTabs].filter(Boolean) as TabsProps[];

  const handleLinkClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    void router.transitionTo('tasks.past');
  };

  const handleTabChange = (key: Key): void => {
    const selectedTab = tabs.find(tab => tab.tab === key);

    if (selectedTab?.event) {
      segment.track(selectedTab.event);
    }

    onTabChange(key);
  };

  return (
    <div className={styles['tabs-container']}>
      <Tabs activeId={activeId} changeTab={handleTabChange}>
        <TabList
          tabs={tabs.map(
            ({ id, title, icon, index, tab, count, hasNotifications, hasDivider }) => ({
              id: tab,
              title,
              count,
              hasNotifications,
              hasDivider,
              icon: icon && TAB_ICONS[icon],
              'data-test-tabs-nav-item': index,
              'data-test-tabs-nav-item-value': id,
            })
          )}
        />
        {canReviewTransferRequest && activeId === TABS.TASKS ? (
          <button
            className={styles['previous-requests']}
            data-testid="previous-requests-link"
            onClick={handleLinkClick}
            type="button"
          >
            {formatMessage({ id: 'supplier-invoices.section.tabs.pending-requests.tasks-link' })}
          </button>
        ) : null}
      </Tabs>
    </div>
  );
}

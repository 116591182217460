import { forwardRef, type ReactNode, type Ref } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import type { Message } from 'qonto/react/graphql';
import { Timeline, TimelineItem } from 'qonto/react/components/timeline';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { Note } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

const JUST_NOW_CUTOFF = 3; /*mins*/
const MINS_AGO_CUTOFF = 60; /*mins*/
const HOURS_AGO_CUTOFF = 24 * 60; /*mins*/
const YESTERDAY_CUTOFF = (24 + 6) * 60; /*mins*/

interface MessageItemProps {
  item: Message;
  'data-testid': string;
}

function MessageCreatedDate({ createdAt }: { createdAt: string }): ReactNode {
  const { locale } = useIntl();

  const getCreatedAtDisplay = (): string => {
    const minsSinceCommentCreated = dayjs(Date.now()).diff(createdAt, 'minute');

    if (minsSinceCommentCreated < JUST_NOW_CUTOFF) {
      return 'Just now';
    }

    if (minsSinceCommentCreated >= JUST_NOW_CUTOFF && minsSinceCommentCreated < MINS_AGO_CUTOFF) {
      return `${String(minsSinceCommentCreated)} mins ago`;
    }

    if (minsSinceCommentCreated >= MINS_AGO_CUTOFF && minsSinceCommentCreated < HOURS_AGO_CUTOFF) {
      return `${String(dayjs(Date.now()).diff(createdAt, 'hour'))} hours ago`;
    }

    if (minsSinceCommentCreated >= HOURS_AGO_CUTOFF && minsSinceCommentCreated < YESTERDAY_CUTOFF) {
      return 'Yesterday';
    }

    return dateToken({
      date: createdAt,
      locale,
      token: 'date-time-s',
    });
  };

  return (
    <time
      className={styles['time-created']}
      data-testid="message-date-created"
      dateTime={createdAt}
    >
      {getCreatedAtDisplay()}
    </time>
  );
}

function MessageItemTitle({ item }: { item: Message }): ReactNode {
  return (
    <div className={styles['title-slot']} data-testid="message-title">
      <div className={styles['full-name']} data-testid="message-author-name">
        {item.author?.membership.fullName}
      </div>
      <time className={styles['time-created']} data-testid="message-date-created">
        <MessageCreatedDate createdAt={item.createdAt} />
      </time>
    </div>
  );
}

const CommentsListItem = forwardRef(function CommentsListItem(
  { item, ...props }: MessageItemProps,
  ref: Ref<HTMLLIElement> | null
): ReactNode {
  const mediaSlot =
    item.id !== 'transaction-note' ? (
      <img
        alt={item.author?.membership.fullName}
        className={styles.avatar}
        data-testid="member-avatar"
        src={item.author?.membership.avatar.url}
      />
    ) : (
      <div className={styles['note-icon']}>
        <Note data-testid="note-icon" />
      </div>
    );

  return (
    <TimelineItem
      item={item}
      lineVariant="solid"
      ref={ref}
      slots={{
        media: mediaSlot,
        title: <MessageItemTitle item={item} />,
        content: (
          <div className={styles['message-content']} data-testid="message-content">
            {item.content}
          </div>
        ),
      }}
      {...props}
    />
  );
});

export function CommentsList({ messages }: { messages: Message[] }): ReactNode {
  return (
    <Timeline
      Item={CommentsListItem}
      className={styles['comments-list']}
      getKey={item => item.id}
      list={messages}
    />
  );
}

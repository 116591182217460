import { useContext, type ReactNode } from 'react';
import type { Uppy } from '@uppy/core';
import { useIntl } from 'react-intl';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { useEventCallback } from 'usehooks-ts';
import { OverlayTriggerStateContext } from 'react-aria-components';
import { PopoverButton, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import { AttachmentUploader } from 'qonto/react/components/table-v2/attachment-uploader';
import { MAX_ATTACHMENT_COUNT, ATTACHMENT_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { useRefetchTransaction } from 'qonto/react/hooks/mutations/use-refetch-transaction';
import { routeContextManager } from 'qonto/react/contexts/route-context';
import {
  ArrowUpLeftOutlined,
  CheckmarkRoundedOutlined,
  SearchOutlined,
} from 'qonto/react/assets/icons';
import { useUpdateAttachmentStatus } from 'qonto/react/hooks/mutations/use-update-attachment-status';
import { AttachmentWarningOutlined } from 'qonto/react/assets/icons/attachment-warning-outlined';
import { LetterOutlined } from 'qonto/react/assets/icons/letter-outlined';
import { useRequestAttachment } from 'qonto/react/hooks/mutations/use-request-attachment';
import styles from './styles.strict-module.css';

interface ActionsPopoverSectionProps {
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
}

interface RequiredActionItemProps {
  required: boolean;
  transactionId: string;
}

function RequiredActionItem({ required, transactionId }: RequiredActionItemProps): ReactNode {
  const { formatMessage } = useIntl();
  const { markAsRequired, markAsNotRequired, isRefetchingTransaction } =
    useUpdateAttachmentStatus(transactionId);
  const segment = useEmberService('segment');
  const isDisabled =
    markAsRequired.isPending || markAsNotRequired.isPending || isRefetchingTransaction;

  const handlePress = useEventCallback(() => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: required ? 'mark_as_not_required' : 'mark_as_required',
      table: 'transactions',
    });
    if (required) {
      markAsNotRequired.mutate();
    } else {
      markAsRequired.mutate();
    }
  });

  if (required) {
    return (
      <PopoverButton
        data-testid="mark-as-not-required"
        isDisabled={isDisabled}
        onPress={handlePress}
        slots={{
          icon: <CheckmarkRoundedOutlined data-testid="mark-as-not-required-icon" />,
          text: formatMessage({
            id: 'transactions.table.attachments.popover.action.mark-as-not-required',
          }),
        }}
      />
    );
  }

  return (
    <PopoverButton
      data-testid="mark-as-required"
      isDisabled={isDisabled}
      onPress={handlePress}
      slots={{
        icon: <ArrowUpLeftOutlined data-testid="mark-as-required-icon" />,
        text: formatMessage({
          id: 'transactions.table.attachments.popover.action.mark-as-required',
        }),
      }}
    />
  );
}

interface LostActionItemProps {
  lost: boolean;
  transactionId: string;
}

function LostActionItem({ lost, transactionId }: LostActionItemProps): ReactNode {
  const { formatMessage } = useIntl();
  const { markAsRetrieved, markAsLost, isRefetchingTransaction } =
    useUpdateAttachmentStatus(transactionId);
  const segment = useEmberService('segment');
  const isDisabled = markAsLost.isPending || markAsRetrieved.isPending || isRefetchingTransaction;

  const handlePress = useEventCallback(() => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: lost ? 'mark_as_retrieved' : 'mark_as_lost',
      table: 'transactions',
    });
    if (lost) {
      markAsRetrieved.mutate();
    } else {
      markAsLost.mutate();
    }
  });

  if (lost) {
    return (
      <PopoverButton
        data-testid="mark-as-retrieved"
        isDisabled={isDisabled}
        onPress={handlePress}
        slots={{
          icon: <ArrowUpLeftOutlined data-testid="mark-as-retrieved-icon" />,
          text: formatMessage({
            id: 'transactions.table.attachments.popover.tooltip.mark-as-retrieved',
          }),
        }}
      />
    );
  }

  return (
    <PopoverButton
      data-testid="mark-as-lost"
      isDisabled={isDisabled}
      onPress={handlePress}
      slots={{
        icon: <AttachmentWarningOutlined data-testid="mark-as-lost-icon" />,
        text: formatMessage({
          id: 'transactions.table.attachments.popover.tooltip.mark-as-lost',
        }),
      }}
    />
  );
}

interface RequestAttachmentActionItemProps {
  transactionId: string;
}

function RequestAttachmentActionItem({
  transactionId,
}: RequestAttachmentActionItemProps): ReactNode {
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { mutate, isPending, isError } = useRequestAttachment(transactionId);
  const segment = useEmberService('segment');

  const handlePress = (): void => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: 'request_attachments',
      table: 'transactions',
    });
    mutate();
    if (isError) {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
    } else {
      toastFlashMessages.toastInfo(formatMessage({ id: 'toasts.attachment_requested' }));
    }
  };

  return (
    <PopoverButton
      data-testid="request-attachments"
      isDisabled={isPending}
      onPress={handlePress}
      slots={{
        icon: <LetterOutlined data-testid="default-icon" />,
        text: formatMessage({ id: 'transactions.table.attachments.popover.action.request' }),
      }}
    />
  );
}

function SuggestedAttachmentsActionItem({
  featureBooleanImproveManualMatching,
}: {
  featureBooleanImproveManualMatching: boolean;
}): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const { id: transactionId } = cellContextManager.useCellContext();
  const { mutate: refetchTransaction } = useRefetchTransaction(transactionId);
  const triggerState = useContext(OverlayTriggerStateContext);
  const { openModal } = routeContextManager.useRouteContext();
  const buttonText = featureBooleanImproveManualMatching
    ? formatMessage({
        id: 'transactions.sidebar.attachments.search-qonto.cta',
      })
    : formatMessage({
        id: 'attachment-auto-matching.transaction-panel.proposition-button',
      });

  const openSuggestedAttachmentsModal = useEventCallback((): void => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: 'find_on_qonto',
      table: 'transactions',
    });
    triggerState.close();
    openModal('attachments/transactions-v2/attachments-suggested/modal-wrapper', {
      transactionId,
      onClose: (closeModal: () => void) => {
        closeModal();
        refetchTransaction();
      },
    });
  });

  return (
    <PopoverButton
      data-testid="find-in-qonto"
      onPress={openSuggestedAttachmentsModal}
      slots={{
        icon: <SearchOutlined data-testid="find-in-qonto-icon" />,
        text: buttonText,
      }}
    />
  );
}

export function ActionsPopoverSection({
  uppy,
  isUploading,
}: ActionsPopoverSectionProps): ReactNode {
  const { formatMessage } = useIntl();
  const transaction = cellContextManager.useCellContext();
  const {
    id: transactionId,
    attachments,
    attachmentSuggestionIds,
    attachmentLost: lost,
    attachmentRequired: required,
  } = transaction;

  const abilities = useEmberService('abilities');

  const hasReachedMaxAttachments = attachments.length >= MAX_ATTACHMENT_COUNT;
  const { featureBooleanImproveManualMatching /*feature--boolean-improve-manual-matching*/ } =
    useFlags();
  const shouldShowSuggestedAttachmentsButton =
    Boolean(featureBooleanImproveManualMatching) ||
    (attachmentSuggestionIds.length && !hasReachedMaxAttachments);
  const canUpdateAttachmentRequiredStatus = abilities.can(
    'update attachment required status transaction'
  );
  const showRequiredActionItem = canUpdateAttachmentRequiredStatus && !lost;
  const showLostActionItem = required;

  const showRequestAttachmentActionItem = abilities.can(
    'request attachments in transaction',
    transaction
  );

  const shouldShowTopActionsSection =
    !hasReachedMaxAttachments ||
    shouldShowSuggestedAttachmentsButton ||
    showRequestAttachmentActionItem;
  const shouldShowBottomActionsSection = showRequiredActionItem || showLostActionItem;

  return (
    <>
      {shouldShowTopActionsSection ? (
        <PopoverSection className={styles.section} data-testid="top-actions-section">
          {showRequestAttachmentActionItem ? (
            <RequestAttachmentActionItem transactionId={transactionId} />
          ) : null}
          <div className={styles.container}>
            {!hasReachedMaxAttachments && (
              <AttachmentUploader
                buttonLabel={formatMessage({
                  id:
                    attachments.length === 0
                      ? 'transactions.table.attachments.popover.action.add'
                      : 'transactions.table.attachments.popover.action.add-more',
                })}
                data-testid="attachment-uploader"
                isDisabled={isUploading}
                uppy={uppy}
              />
            )}
            {shouldShowSuggestedAttachmentsButton ? (
              <SuggestedAttachmentsActionItem
                featureBooleanImproveManualMatching={Boolean(featureBooleanImproveManualMatching)}
              />
            ) : null}
          </div>
        </PopoverSection>
      ) : null}
      {shouldShowBottomActionsSection ? (
        <PopoverSection className={styles.section} data-testid="bottom-actions-section">
          <div className={styles.container}>
            {showRequiredActionItem ? (
              <RequiredActionItem required={required} transactionId={transactionId} />
            ) : null}
            {showLostActionItem ? (
              <LostActionItem lost={lost} transactionId={transactionId} />
            ) : null}
          </div>
        </PopoverSection>
      ) : null}
    </>
  );
}

import dayjs from 'dayjs';
import type { CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';
import type { CashflowTimeseries as CashflowTimeseriesApiResponse } from 'qonto/react/api/models/cash-flow-timeseries';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';

export const mockTimeseriesResponse: CashflowTimeseriesApiResponse = {
  timeframe: {
    inclusive_start_date: dayjs().subtract(12, 'months').startOf('month').toISOString(),
    exclusive_end_date: dayjs().startOf('month').toISOString(),
  },
  categories_data: {
    inflow: [
      {
        id: 'cat_uuid_1',
        name: 'Tax Income',
        name_key: 'inflows.taxes.corporate-credit',
        color_key: 'category_green',
        icon_key: 'icon_calculator',
        subcategories: [
          {
            id: 'sub_uuid_1',
            name: 'Salaries',
            name_key: 'inflows.taxes.salary-deductions',
            color_key: 'category_green',
          },
        ],
      },
    ],
    outflow: [
      {
        id: 'cat_uuid_2',
        name: 'Operational Expenses',
        name_key: 'outflows.operational',
        color_key: 'category_blue',
        icon_key: 'icon_box',
        subcategories: [
          {
            id: 'sub_uuid_2',
            name: 'Office Supplies',
            name_key: 'office_supplies',
            color_key: 'category_blue',
          },
        ],
      },
    ],
  },
  timeframes: [],
};

let startingAmount = 1800.0;
let inflowAmount = 1100.0;
let outflowAmount = -900.0;
let transactionsCount = 26;
let vatCreditAmount = 37.25;
let vatDebitAmount = 180.0;

for (let i = 11; i >= 0; i--) {
  const startDate = dayjs().subtract(i, 'months').startOf('month');
  const endDate = startDate.add(1, 'month');

  startingAmount += 200.0;
  inflowAmount += 300.0;
  outflowAmount -= 100.0;
  transactionsCount += 2;
  vatCreditAmount += 10.0;
  vatDebitAmount += 20.0;

  mockTimeseriesResponse.timeframes.push({
    inclusive_start_date: startDate.toISOString(),
    exclusive_end_date: endDate.toISOString(),
    cash_flow_data: {
      vat: {
        credit_amount: {
          value: vatCreditAmount,
          currency: 'EUR',
        },
        debit_amount: {
          value: vatDebitAmount,
          currency: 'EUR',
        },
      },
      transactions_count: transactionsCount,
      starting_amount: {
        value: startingAmount,
        currency: 'EUR',
      },
      ending_amount: {
        value: startingAmount + 200.0,
        currency: 'EUR',
      },
      inflows: {
        amount_sum: {
          value: inflowAmount,
          currency: 'EUR',
        },
        growth_rate_over_previous_period: 0.2,
        transactions_count: transactionsCount / 2,
        categories_data_points: [
          {
            category_id: 'cat_uuid_1',
            transactions_count: transactionsCount / 2,
            amount_sum: {
              value: inflowAmount,
              currency: 'EUR',
            },
            subcategories_data_points: [
              {
                subcategory_id: 'sub_uuid_1',
                transactions_count: transactionsCount / 2,
                amount_sum: {
                  value: inflowAmount,
                  currency: 'EUR',
                },
              },
            ],
          },
        ],
      },
      outflows: {
        amount_sum: {
          value: outflowAmount,
          currency: 'EUR',
        },
        growth_rate_over_previous_period: 0.2,
        transactions_count: transactionsCount / 2,
        categories_data_points: [
          {
            category_id: 'cat_uuid_2',
            transactions_count: transactionsCount / 2,
            amount_sum: {
              value: outflowAmount,
              currency: 'EUR',
            },
            subcategories_data_points: [
              {
                subcategory_id: 'sub_uuid_2',
                transactions_count: transactionsCount / 2,
                amount_sum: {
                  value: outflowAmount,
                  currency: 'EUR',
                },
              },
            ],
          },
        ],
      },
    },
  });
}

export const mockTimeseries: CashflowTimeseries = camelizeKeys(mockTimeseriesResponse);

import { type ReactNode } from 'react';
import type { Row, Table } from '@tanstack/react-table';
import { Checkbox } from 'qonto/react/components/table-v2/cells/checkbox';
import type { Transaction } from 'qonto/react/graphql';

interface BulkCheckboxHeaderProps {
  table: Table<Transaction>;
}
export function BulkCheckboxHeader({ table }: BulkCheckboxHeaderProps): ReactNode {
  const isIndeterminate = table.getIsSomeRowsSelected();
  const isChecked = table.getIsAllRowsSelected();
  const toggleAll = table.getToggleAllRowsSelectedHandler();
  const resetAll = (): void => {
    table.resetRowSelection();
  };
  const onChange = !isChecked && isIndeterminate ? resetAll : toggleAll;

  return <Checkbox checked={isChecked} indeterminate={isIndeterminate} onChange={onChange} />;
}

interface BulkCheckboxCellProps {
  row: Row<Transaction>;
}
export function BulkCheckboxCell({ row }: BulkCheckboxCellProps): ReactNode {
  return (
    <Checkbox
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  );
}

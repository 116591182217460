import React, { type CSSProperties, type ReactNode } from 'react';
import {
  Button,
  IconArrowSmallBottomFilled,
  IconArrowSmallTopFilled,
  IconDotsOutlined,
  IconPlusOutlined,
} from '@repo/design-system-kit';
import cx from 'clsx';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragCellHandle } from 'qonto/react/assets/icons';
import { Category } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
import type { CashflowCategory } from 'qonto/react/models/cash-flow-category';
import styles from './category-item.strict-module.css';

interface CategoryItemProps {
  category: CashflowCategory;
  isExpanded?: boolean;
  isSubcategory?: boolean;
  canExpand?: boolean;
  onAddSubcategory?: () => void;
  onToggleAccordion?: () => void;
}

export function CategoryItem({
  category,
  isSubcategory = false,
  canExpand = false,
  isExpanded = false,
  onAddSubcategory,
  onToggleAccordion,
}: CategoryItemProps): ReactNode {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: category.id ?? '',
  });

  const dndStyle: CSSProperties = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: isDragging ? 1.01 : 1,
        scaleY: isDragging ? 1.01 : 1,
      }
    ),
    transition,
    zIndex: isDragging ? 10 : 'auto',
    position: isDragging ? 'relative' : 'static',
  };

  return (
    <div
      className={cx(
        styles.container,
        isExpanded && styles.expanded,
        isSubcategory && styles.subcategory,
        !isSubcategory && !canExpand && styles['no-hover']
      )}
      data-testid={`${isSubcategory ? '' : 'parent-'}category-item`}
      ref={setNodeRef}
      style={dndStyle}
    >
      <button
        className={styles['drag-handle']}
        data-testid="drag-handle"
        disabled={isExpanded}
        type="button"
        {...attributes}
        {...listeners}
      >
        <DragCellHandle />
      </button>
      {canExpand ? (
        <Button
          className={styles['toggle-accordion']}
          data-testid="toggle-accordion"
          iconOnly
          onPress={onToggleAccordion}
          variant="tertiary"
        >
          <div className={styles['category-details']} data-testid="category-details">
            <Category avatarSize={32} emphasized item={category} showIcon={!isSubcategory} />
            <div className={styles['icon-container']}>{getTriggerIcon(isExpanded)}</div>
          </div>
        </Button>
      ) : (
        <div className={styles['category-details']} data-testid="category-details">
          <Category avatarSize={32} emphasized item={category} showIcon={!isSubcategory} />
        </div>
      )}
      <div className={styles['menu-block']}>
        {onAddSubcategory ? (
          <Button
            data-testid="subcategory-create"
            iconOnly
            onPress={onAddSubcategory}
            size="small"
            variant="tertiary"
          >
            <IconPlusOutlined height={16} width={16} />
          </Button>
        ) : null}
        <Button data-testid="category-menu" iconOnly size="small" variant="tertiary">
          <IconDotsOutlined height={16} width={16} />
        </Button>
      </div>
    </div>
  );
}

function getTriggerIcon(isExpanded: boolean): ReactNode {
  return isExpanded ? (
    <IconArrowSmallTopFilled data-testid="icon-expanded" height={16} width={16} />
  ) : (
    <IconArrowSmallBottomFilled data-testid="icon-collapsed" height={16} width={16} />
  );
}

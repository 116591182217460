import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useEffect, useState } from 'react';

export const useCategoriesManagementRouting = (): {
  isOutflows: boolean;
} => {
  const emberRouter = useEmberService('router');
  const [isOutflows, setIsOutflows] = useState(emberRouter.currentURL.includes('/outflows'));

  useEffect(() => {
    const handleRouteChange = (): void => {
      setIsOutflows(emberRouter.currentURL.includes('/outflows'));
    };

    emberRouter.on('routeDidChange', handleRouteChange);
    return () => {
      emberRouter.on('routeDidChange', handleRouteChange);
    };
  }, [emberRouter]);

  return { isOutflows };
};

import React, { type ReactNode } from 'react';
import { Avatar } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface ApplicationDataLogo {
  url: string;
  alt?: string;
}

interface ApplicationData {
  name: string;
  logo?: ApplicationDataLogo;
}

interface ApplicationCardProps {
  application: ApplicationData;
}

export function ApplicationCard({ application }: ApplicationCardProps): ReactNode {
  return (
    <div className={styles['card-container']}>
      <div className={styles['application-logo']}>
        {application.logo?.url ? (
          <Avatar
            alt={application.logo.alt ?? application.name}
            data-test-application-logo
            loading="eager"
            size="40"
            src={application.logo.url}
            withBorder
          />
        ) : null}
      </div>
      <div className={styles['application-name']}>
        <h4
          className={styles['application-title']}
          data-test-application-card-name={application.name}
        >
          {application.name}
        </h4>
      </div>
    </div>
  );
}

import React, { type ReactNode } from 'react';
import { PageHeader } from './page-header';
import styles from './categories-management-page.strict-module.css';
import { CategoriesManagementTabs } from './categories-tabs';

export function CategoriesManagementPage(): ReactNode {
  return (
    <div className={styles.wrapper}>
      <PageHeader />
      <CategoriesManagementTabs />
    </div>
  );
}

import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popup } from '@repo/design-system-kit';
import window from 'ember-window-mock';
import { useEffect, useState, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import styles from './styles.strict-module.css';

const isTest = ENV.environment === 'test';

export const BOI_REFUND_POPUP_STORAGE_KEY = 'boi-refund-popup-dismissed';

const popupDelay = ENV.environment === 'test' ? 0 : 2000;

export function BoiRefundPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  useEffect(() => {
    if (window.localStorage.getItem(BOI_REFUND_POPUP_STORAGE_KEY) === '1') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('boi_refund_popup_displayed');
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleClick = (): void => {
    window.localStorage.setItem(BOI_REFUND_POPUP_STORAGE_KEY, '1');
    setIsOpen(false);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      className={styles.popup}
      confirmText={formatMessage({ id: 'overview.promotional-modal.boi-refund.cta' })}
      data-test-id="boi-refund-popup"
      illustration={
        <img
          alt=""
          data-test-id="boi-refund-popup-illustration"
          src="/illustrations/boi-refund-popup/illustration.png"
          srcSet="/illustrations/boi-refund-popup/illustration@2x.png"
        />
      }
      isOpen={isOpen}
      onCancel={handleClick}
      onConfirm={handleClick}
      title={formatMessage({ id: 'overview.promotional-modal.boi-refund.title' })}
      type="promotional"
    >
      <p data-test-id="boi-refund-popup-description">
        <FormattedMessage id="overview.promotional-modal.boi-refund.description" />
      </p>
    </Popup>
  );
}

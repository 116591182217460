import { type ReactNode } from 'react';
import cx from 'clsx';
import { isNil } from 'es-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.strict-module.css';

const VAT_STATUS = {
  Auto: 'vat_auto',
  Manual: 'vat_manual',
  Confirmed: 'vat_confirmed',
  Undetected: 'vat_not_found',
} as const;

type VatStatus = (typeof VAT_STATUS)[keyof typeof VAT_STATUS];

type Amount =
  | {
      value: number | null | undefined;
      currency?: string;
    }
  | null
  | undefined;

interface VatItem {
  amount: Amount;
  amountExcludingVat: Amount;
  rate: number;
}

interface VatDetailsProps {
  vatItems?: VatItem[];
  totalAmount: Amount;
  status: VatStatus;
}

const generateShortId = (): string => Math.random().toString(36).substring(2, 10);

export function VatDetails({ vatItems, totalAmount, status }: VatDetailsProps): ReactNode {
  const { formatNumber } = useIntl();

  const format = (amount: Amount): string => {
    if (!amount) return UNDEFINED_DISPLAY;

    const { value, currency } = amount;

    if (isNil(value)) return UNDEFINED_DISPLAY;

    return formatNumber(value, {
      style: 'currency',
      currency: currency ?? 'EUR',
      currencyDisplay: 'symbol',
    });
  };

  const formatPercent = (rate: VatItem['rate']): string => {
    if (!Number.isFinite(rate)) return UNDEFINED_DISPLAY;

    const value = rate / 100;

    const options = {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: Number.isInteger(value) ? 0 : 1,
    } as const;

    return formatNumber(value, options);
  };

  const lines = vatItems?.map(({ amount, amountExcludingVat, rate }) => ({
    amount: format(amount),
    amountExcludingVat: format(amountExcludingVat),
    rate: formatPercent(rate),
    key: generateShortId(),
  }));

  const isMultiple = lines && lines.length > 1;
  const isEmpty = !vatItems?.length;
  const isUndetected = status === VAT_STATUS.Undetected;
  const totalVatAmount = format(totalAmount);

  return (
    <div className={styles['vat-section']} data-test-vat-section>
      {isEmpty ? (
        <p className={styles['empty-state']} data-test-vat-empty-state>
          <FormattedMessage
            id={
              isUndetected
                ? 'transactions.sidebar.bookkeeping.vat-not-detected'
                : 'transactions.sidebar.bookkeeping.no-vat'
            }
          />
        </p>
      ) : (
        <>
          <table className={cx(styles['vat-table'], isMultiple && [styles.multiple])}>
            <thead className={styles['vat-table-header']}>
              <tr>
                <th
                  className={cx(styles['vat-table-title'], styles['amount-excluding-vat'])}
                  data-test-vat-amount-excluding-vat-title
                >
                  <FormattedMessage id="labels.bookkeeping.amount-excluding-vat" />
                </th>
                <th
                  className={cx(styles['vat-table-title'], styles['vat-rate'])}
                  data-test-vat-rate-title
                >
                  <FormattedMessage id="labels.bookkeeping.vat-rate" />
                </th>
                <th
                  className={cx(styles['vat-table-title'], styles['vat-amount'])}
                  data-test-vat-amount-title
                >
                  <FormattedMessage id="labels.bookkeeping.vat-amount" />
                </th>
              </tr>
            </thead>
            <tbody>
              {lines?.map((line, index) => (
                <tr className={styles['vat-line']} key={line.key}>
                  <td
                    className={styles['amount-excluding-vat']}
                    data-test-vat-amount-excluding-vat={index}
                  >
                    {line.amountExcludingVat}
                  </td>
                  <td className={styles['vat-rate']} data-test-vat-rate={index}>
                    {line.rate}
                  </td>
                  <td className={styles['vat-amount']} data-test-vat-amount={index}>
                    {line.amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isMultiple ? (
            <dl className={styles['total-vat-amount']}>
              <dt className={styles['total-vat-amount-title']} data-test-total-vat-amount-title>
                <FormattedMessage id="labels.bookkeeping.total-vat-amount" />
              </dt>
              <dd data-test-total-vat-amount>{totalVatAmount}</dd>
            </dl>
          ) : null}
        </>
      )}
    </div>
  );
}

const UNDEFINED_DISPLAY = '-';

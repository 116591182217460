import { TabList, TabPanel, Tabs } from '@repo/design-system-kit';
import React, { type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import type { Key } from 'react-aria';
import { useIntl } from 'react-intl';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { TABS } from '../constants';
import { CategoriesList } from '../categories-list';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import styles from './categories-tabs.strict-module.css';

export function CategoriesManagementTabs(): ReactNode {
  const segment = useSegment();
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const { formatMessage } = useIntl();

  const { isOutflows } = useCategoriesManagementRouting();

  const activeId = isOutflows ? TABS.OUTFLOWS : TABS.INFLOWS;

  const handleChangeTab = (key: Key): void => {
    segment.track(`cash-flow-categories_${key.toString()}_select`);
    void router.push(`/organizations/${organization.slug}/cash-flow-categories/${key.toString()}`);
  };

  const tabs = [
    {
      id: TABS.INFLOWS,
      title: formatMessage({ id: 'categories-management.inflows.tab-title' }),
      content: <CategoriesList type={TABS.INFLOWS} />,
      index: 1,
    },
    {
      id: TABS.OUTFLOWS,
      title: formatMessage({ id: 'categories-management.outflows.tab-title' }),
      content: <CategoriesList type={TABS.OUTFLOWS} />,
      index: 2,
    },
  ];

  return (
    <nav data-testid="categories-management-tabs">
      <Tabs activeId={activeId} changeTab={handleChangeTab}>
        <TabList
          tabs={tabs.map(({ id, title, index }) => ({
            id,
            title,
            'data-test-tabs-nav-item': index,
            'data-test-tabs-nav-item-value': id,
          }))}
        />
        <div className={styles['tab-panel-container']}>
          {tabs.map(tab => (
            <TabPanel key={tab.id} tabId={tab.id}>
              {tab.content}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </nav>
  );
}

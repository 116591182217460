import { useRef, useState } from 'react';
import type { Dispatch, ReactNode, RefObject, SetStateAction } from 'react';
import { Popover, CheckboxGroup } from 'react-aria-components';
import { Checkbox, Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { FilterButton } from '../filter-button';
import styles from './multi-select-filter.strict-module.css';

interface SelectOption {
  text: string;
  value: string;
}

interface MultiSelectFilterProps {
  placeholder: string;
  options: SelectOption[];
  selected: SelectOption[];
  onSubmit: (selected: SelectOption[]) => void;
}

export function MultiSelectFilter({
  placeholder,
  options,
  selected,
  onSubmit,
}: MultiSelectFilterProps): ReactNode {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <FilterButton
        data-test-multi-select-filter-trigger
        isActive={Boolean(selected.length)}
        isOpen={isOpen}
        onClick={() => {
          setIsOpen(state => !state);
        }}
        ref={triggerRef}
      >
        {placeholder}
      </FilterButton>
      <MultiSelectPopover
        close={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        onSubmit={onSubmit}
        options={options}
        selected={selected}
        setIsOpen={setIsOpen}
        triggerRef={triggerRef}
      />
    </>
  );
}

interface MutliSelectPopoverProps {
  isOpen: boolean;
  triggerRef: RefObject<HTMLButtonElement>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  options: SelectOption[];
  selected: SelectOption[];
  onSubmit: (selected: SelectOption[]) => void;
  close: () => void;
}

function MultiSelectPopover({
  isOpen,
  triggerRef,
  setIsOpen,
  options,
  selected,
  onSubmit,
  close,
}: MutliSelectPopoverProps): ReactNode {
  const [internalSelection, setInternalSelection] = useState<string[]>(
    selected.map(({ value }) => value)
  );

  const { formatMessage } = useIntl();

  const submitSelection = (): void => {
    onSubmit(options.filter(opt => internalSelection.includes(opt.value)));
    close();
  };

  return (
    <Popover
      UNSTABLE_portalContainer={document.getElementById('ember-testing') ?? document.body}
      className={styles.popover}
      data-test-multi-select-filter-popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      placement="bottom left"
      triggerRef={triggerRef}
    >
      <CheckboxGroup
        className={styles['checkboxes-wrapper']}
        onChange={setInternalSelection}
        value={internalSelection}
      >
        {options.map(option => (
          <Checkbox
            className={styles.item}
            data-test-multi-select-checkbox={option.value}
            key={option.value}
            value={option.value}
          >
            {option.text}
          </Checkbox>
        ))}
      </CheckboxGroup>
      <footer className={styles.footer}>
        <Button
          data-test-multi-select-submit
          onPress={submitSelection}
          size="small"
          type="button"
          variant="primary"
        >
          {formatMessage({ id: 'btn.apply' })}
        </Button>
        {internalSelection.length ? (
          <Button
            data-test-multi-select-reset
            onPress={() => {
              setInternalSelection([]);
            }}
            size="small"
            type="button"
            variant="tertiary"
          >
            {formatMessage({ id: 'btn.reset' })}
          </Button>
        ) : null}
      </footer>
    </Popover>
  );
}

import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { closestCorners, DndContext, type DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import type { CashflowParentCategory } from 'qonto/react/models/cash-flow-category';
import { useCashFlowCategoriesStateStorage } from 'qonto/react/hooks/use-cash-flow-categories-storage';
import { useUpdateCashFlowSubcategory } from 'qonto/react/hooks/use-update-cash-flow-category';
import { CategoryItem } from '../category-item';
import styles from './category-item-group.strict-module.css';
import { useDragAndDrop } from './use-drag-and-drop';

interface CategoryItemGroupProps {
  category: CashflowParentCategory;
}
export function CategoryItemGroup({ category }: CategoryItemGroupProps): ReactNode {
  const segment = useEmberService('segment');
  const { mutate } = useUpdateCashFlowSubcategory();
  const { isExpanded, setIsExpanded } = useCashFlowCategoriesStateStorage(
    'cash-flow-categories-management-expanded-states',
    category.id,
    true
  );

  const { categories: subcategories, onDragEnd, sensors } = useDragAndDrop(category.subcategories);
  const hasSubcategories = subcategories.length > 0;
  const showSubcategories = hasSubcategories && isExpanded;

  const handleAddSubcategory = (): void => {
    segment.track('cash-flow-categories_subcategory_add_clicked');
  };

  const handleToggleAccordion = (): void => {
    setIsExpanded(!isExpanded);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, updatedCategories: updatedSubcategories } = onDragEnd(event);

    if (updatedSubcategories.length > 0) {
      const order = updatedSubcategories.findIndex(subcategory => subcategory.id === active.id);
      mutate({ categoryId: category.id, subcategoryId: active.id.toString(), payload: { order } });
    }
  };

  return (
    <div className={styles.container}>
      <CategoryItem
        canExpand={hasSubcategories}
        category={category}
        isExpanded={showSubcategories}
        onAddSubcategory={handleAddSubcategory}
        onToggleAccordion={handleToggleAccordion}
      />
      {showSubcategories ? (
        <div>
          <DndContext
            collisionDetection={closestCorners}
            modifiers={[restrictToParentElement]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext items={subcategories} strategy={verticalListSortingStrategy}>
              {subcategories.map(subcategory => (
                <CategoryItem category={subcategory} isSubcategory key={subcategory.id} />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      ) : null}
    </div>
  );
}

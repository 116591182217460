import React, { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import styles from './page-header.strict-module.css';

export function PageHeader(): ReactNode {
  const segment = useSegment();
  const { formatMessage } = useIntl();

  const handleClick = (): void => {
    segment.track('cash-flow-categories_add-category_clicked');
  };

  return (
    <header className={styles.header} data-testid="page-header">
      <div className={styles['title-group']}>
        <h1 className="title-1" data-testid="title">
          {formatMessage({ id: 'categories-management.page-header.title' })}
        </h1>
        <p className="body-2" data-testid="subtitle">
          {formatMessage({ id: 'categories-management.page-header.subtitle' })}
        </p>
      </div>
      <Button
        data-testid="add-category-button"
        onPress={handleClick}
        size="medium"
        variant="primary"
      >
        {formatMessage({ id: 'categories-management.page-header.add-category.cta' })}
      </Button>
    </header>
  );
}

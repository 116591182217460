import { useContext, type ReactNode } from 'react';
import { BadgeStatus } from '@repo/design-system-kit';
import { OverlayTriggerStateContext } from 'react-aria-components';
import {
  Popover,
  PopoverHeader,
  PopoverSection,
  PopoverButton,
  type OverlayTriggerState,
} from 'qonto/react/components/table-v2/popover';
import { CheckmarkRoundedOutlined, CrossRoundedOutlined } from 'qonto/react/assets/icons';
import type { Transaction } from 'qonto/react/graphql';
import { getVerificationStatusInfo } from '../util';
import styles from './styles.strict-module.css';

interface PopoverProps {
  toggleVerificationStatus: (close: () => void) => void;
  status: Transaction['status'];
  qualifiedForAccounting: boolean;
  isUpdatingStatus: boolean;
}

export function VerificationStatusCellPopover({
  toggleVerificationStatus,
  status,
  qualifiedForAccounting,
  isUpdatingStatus,
}: PopoverProps): ReactNode {
  const triggerState = useContext<OverlayTriggerState>(OverlayTriggerStateContext);
  const statusBadgeInfo = getVerificationStatusInfo(status, qualifiedForAccounting);
  const handlePress = (): void => {
    toggleVerificationStatus(triggerState.close);
  };

  if (!statusBadgeInfo) {
    return null;
  }

  return (
    <Popover>
      <PopoverHeader className={styles.header}>
        <BadgeStatus {...statusBadgeInfo} />
      </PopoverHeader>
      <PopoverSection className={styles.section}>
        {qualifiedForAccounting ? (
          <PopoverButton
            data-testid="cancel-verification"
            isDisabled={isUpdatingStatus}
            onPress={handlePress}
            slots={{
              icon: <CrossRoundedOutlined />,
              text: 'Cancel verification',
            }}
          />
        ) : (
          <PopoverButton
            data-testid="mark-as-verified"
            isDisabled={isUpdatingStatus}
            onPress={handlePress}
            slots={{
              icon: <CheckmarkRoundedOutlined />,
              text: 'Mark as verified',
            }}
          />
        )}
      </PopoverSection>
    </Popover>
  );
}

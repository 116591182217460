import cx from 'clsx';
import React, { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import type { TransactionModel } from 'qonto/react/models/transaction';
import { useAssignTransactionsCategory } from 'qonto/react/hooks/use-assign-transactions-category';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useCategorizationRulesManager } from 'qonto/react/hooks/use-categorization-rules-manager';
import type {
  CashflowActiveCategoryId,
  CashflowCategoryAssignment,
} from 'qonto/react/models/cash-flow-category';
import type { MemorizeCategoriesRule } from 'qonto/react/models/memorize-category';
import { CashflowCategorySelector } from './category-selector';
import styles from './styles.strict-module.css';
import { CashFlowCategoryMemorizeWidget } from './memorize-widget';

interface CashflowCategoryManagerProps {
  activeCategoryId: CashflowActiveCategoryId;
  transactions: TransactionModel[];
  onBulkMutate?: ({
    categoryId,
    callback,
  }: {
    categoryId: string | null;
    callback: () => void;
  }) => void;
}

export function CashflowCategoryManager({
  activeCategoryId = null,
  transactions,
  onBulkMutate,
}: CashflowCategoryManagerProps): ReactNode {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager();
  const categorizationManager = useCategorizationRulesManager();
  const { data: categories, isPending } = useFetchCashflowCategories(organization.id);
  const { mutate } = useAssignTransactionsCategory();

  const abilities = useEmberService('abilities');
  const canManageCashFlowCategories = abilities.can('manage category');

  const [showMemorizeWidget, setShowMemorizeWidget] = useState(false);
  const isMemorizeWidgetActive = canManageCashFlowCategories && showMemorizeWidget;

  const transactionIds = transactions.map(transaction => transaction.id);

  const isAutoCategorized = (): boolean => {
    if (transactions.length > 1) return false;
    return Boolean(
      transactions[0]?.categoryAssignment?.source &&
        !['manual', 'rule'].includes(transactions[0].categoryAssignment.source)
    );
  };

  const onMutate = (categoryId: CashflowActiveCategoryId): void => {
    const assignment: CashflowCategoryAssignment = {
      transactionIds,
      categoryId,
    };
    categorizationManager.categorizeTransactions(assignment);
    setShowMemorizeWidget(true);
  };

  const handleCategoryChange = (categoryId: CashflowActiveCategoryId): void => {
    const callback = (): void => {
      mutate({ transactionIds, categoryId, onMutate });
    };
    onBulkMutate ? onBulkMutate({ categoryId, callback }) : callback();
  };

  const onGenerateRules = (rules: MemorizeCategoriesRule[]): void => {
    setShowMemorizeWidget(false);
    categorizationManager.createCashFlowCategoryRules(rules);
  };

  const handleRedirectToCashflow = (): void => {
    segment.track('transaction_cash-flow-promotion_clicked');
    void router.push(`/organizations/${organization.slug}/cash-flow`);
  };

  const selectableCategories = categories ?? { inflow: [], outflow: [] };

  return (
    <>
      <CashflowCategorySelector
        activeCategoryId={activeCategoryId}
        categories={selectableCategories}
        isAutoCategorized={isAutoCategorized()}
        isLoading={isPending}
        onSelectionChange={handleCategoryChange}
      />
      {!activeCategoryId ? (
        <Disclaimer.Inline
          className={styles.disclaimer}
          data-testid="cash-flow-category-manager-disclaimer"
        >
          {formatMessage(
            { id: 'transactions.sidebar.cash-flow-categories.disclaimer.text' },
            {
              cashFlow: (
                <button
                  className={cx('body-link', styles['disclaimer-link'])}
                  data-testid="cash-flow-category-manager-link"
                  key="cash-flow-category-manager-link"
                  onClick={handleRedirectToCashflow}
                  type="button"
                >
                  {formatMessage({
                    id: 'transactions.sidebar.cash-flow-categories.disclaimer.link',
                  })}
                </button>
              ),
            }
          )}
        </Disclaimer.Inline>
      ) : null}
      {isMemorizeWidgetActive ? (
        <CashFlowCategoryMemorizeWidget
          categoryId={activeCategoryId}
          data-testid="cash-flow-category-memorize-widget"
          onGenerateRules={onGenerateRules}
          transactions={transactions}
        />
      ) : null}
    </>
  );
}

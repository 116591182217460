import { useMemo, type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { useTernaryDarkMode } from 'usehooks-ts';
// @ts-expect-error -- TODO remove this when ui-kit constants are typed
import { APPEARANCE_KEY } from '@qonto/ui-kit/constants/appearance-setting';

interface ThemedAssetProps extends Omit<ComponentPropsWithoutRef<'img'>, 'src'> {
  assetPath: string;
}

const reqWithContext = require.context('../../../static/', true, /\.[a-z]+$/) as (
  path: string
) => string;

const getAssetModulePath = (path: string): string | null => {
  try {
    return reqWithContext(`./${path.replace(/^\//, '')}`);
  } catch {
    return null;
  }
};

export function StaticThemedAsset({ assetPath, ...props }: ThemedAssetProps): ReactNode {
  const { isDarkMode } = useTernaryDarkMode({
    localStorageKey: APPEARANCE_KEY as string,
    initializeWithValue: true,
  });

  const assetModulePath = useMemo(() => {
    const themedIconPath = isDarkMode
      ? assetPath.replace(/(?<extension>\.[a-z]+)$/, '-dark$1')
      : assetPath;
    let modulePath = getAssetModulePath(themedIconPath);

    if (isDarkMode && !modulePath) {
      modulePath = getAssetModulePath(assetPath);
    }

    if (!modulePath) {
      throw new Error(`Could not find asset for path: ${assetPath}`);
    }

    return modulePath;
  }, [isDarkMode, assetPath]);

  return <img alt="" data-test-themed-asset src={assetModulePath} {...props} />;
}

/* eslint-disable camelcase -- API adapter */
import type {
  CashflowTimeframe,
  CashflowTimeseries,
  TimeframeInterval,
} from 'qonto/react/api/models/cash-flow-timeseries';
import type { CashflowSide } from 'qonto/react/models/cash-flow-timeseries';
import type { MonthlyBalance, MonthlyFlow } from '../api/balance';
import type { AggregateAmounts } from '../api/statistics';
import type { ComboChartStatistics } from '../api/combo-chart';

interface StatisticsInterval {
  start_date: number;
  end_date: number;
}

export function buildStatisticsInterval(timeframe: TimeframeInterval): StatisticsInterval {
  const start_date = Date.parse(timeframe.inclusive_start_date);
  const end_date = Date.parse(timeframe.exclusive_end_date);
  return { start_date, end_date };
}

export function getMonthlyCashflow(
  timeframe: CashflowTimeframe,
  side: CashflowSide,
  interval: StatisticsInterval
): MonthlyFlow {
  const datapoint = timeframe.cash_flow_data[side];
  const aggregateAmounts: AggregateAmounts = {
    amount_count: datapoint.transactions_count,
    amount_sum: Number(datapoint.amount_sum.value).toFixed(2),
    growth_rate: datapoint.growth_rate_over_previous_period,
  };

  return {
    data: aggregateAmounts,
    ...interval,
  };
}

export function getMonthlyBalance(
  timeframe: CashflowTimeframe,
  interval: StatisticsInterval
): MonthlyBalance {
  const datapoint = timeframe.cash_flow_data;

  return {
    data: {
      starting_balance: Number(datapoint.starting_amount.value).toFixed(2),
      ending_balance: Number(datapoint.ending_amount.value).toFixed(2),
    },
    ...interval,
  };
}

export function mapTimeseriesToStatistics(timeseries: CashflowTimeseries): ComboChartStatistics {
  const inflowMonthlyData: MonthlyFlow[] = [];
  const outflowMonthlyData: MonthlyFlow[] = [];
  const balanceMonthlyData: MonthlyBalance[] = [];
  const { start_date: min, end_date: max } = buildStatisticsInterval(timeseries.timeframe);
  const meta = { bounds: { min, max } };

  for (const timeframe of timeseries.timeframes) {
    const interval = buildStatisticsInterval(timeframe);

    inflowMonthlyData.push(getMonthlyCashflow(timeframe, 'inflows', interval));
    outflowMonthlyData.push(getMonthlyCashflow(timeframe, 'outflows', interval));
    balanceMonthlyData.push(getMonthlyBalance(timeframe, interval));
  }

  const inflowFlowStatistics = { data: { display_at_monthly: inflowMonthlyData }, meta };
  const outflowFlowStatistics = { data: { display_at_monthly: outflowMonthlyData }, meta };
  const balanceStatistics = { data: { display_at_monthly: balanceMonthlyData }, meta };

  return [inflowFlowStatistics, outflowFlowStatistics, balanceStatistics];
}

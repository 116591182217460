import type { ColumnDef, Row } from '@tanstack/react-table';
import { type Transaction } from 'qonto/react/graphql';
import { type LabelList } from 'qonto/react/models/label';
import { DateCell } from '../../table-v2/cells/date-cell/date-cell';
import { AmountCell } from '../../table-v2/cells/amount-cell';
import { CaptionCell } from '../../table-v2/cells/caption-cell';
import { StatusCell } from './cells/status-cell';
import { TransactionCell } from './cells/transaction-cell';
import { AttachmentCell } from './cells/attachment-cell';
import { CategoryCell } from './cells/category-cell';
import { LabelCell } from './cells/label-cell';
import { PaymentMethodCell } from './cells/payment-method';
import { BulkCheckboxCell, BulkCheckboxHeader } from './cells/bulk-checkbox';
import { CommentsCell } from './cells/comments-cell';
import { MembershipCell } from './cells/membership-cell';
import { VerificationStatusCell } from './cells/verification-status-cell';

export const generateColumns = (
  labelLists: LabelList[]
): { columns: ColumnDef<Transaction>[]; defaultColumnOrder: string[] } => {
  const { labelColumnsIds, labelColumns } = createLabelColumns(labelLists);

  const aggregatedColumns: ColumnDef<Transaction>[] = [...staticColumns, ...labelColumns];

  const defaultColumnOrder: string[] = [
    'transactions',
    'amount',
    'paymentMethod',
    'operationDate',
    'comments',
    'attachments',
    'membership',
    'bkVerificationStatus',
    'status',
    'category',
    'reference',
    ...labelColumnsIds,
    // 'settlementDate',
    // 'source',
    // 'settledBalance',
    // 'target',
  ];

  return {
    columns: !labelLists.length ? staticColumns : aggregatedColumns,
    defaultColumnOrder,
  };
};

const createLabelColumns = (
  labelLists: LabelList[]
): {
  labelColumnsIds: string[];
  labelColumns: ColumnDef<Transaction>[];
} => {
  const labelColumnsIds: string[] = [];

  const labelColumns = labelLists.map(labelList => {
    // Tanstack table breaks with ids containing '-'
    const listId = labelList.id.replaceAll('-', '_');
    labelColumnsIds.push(listId);

    return {
      id: listId,
      accessorKey: listId,
      header: `Labels (${labelList.name})`,
      cell: ({ row }: { row: Row<Transaction> }) => {
        const matchingTransactionLabel = row.original.labels.find(
          label => label.listId === labelList.id
        );

        return <LabelCell label={matchingTransactionLabel} labelList={labelList} />;
      },
      size: 160,
    };
  });

  return {
    labelColumnsIds,
    labelColumns,
  };
};

const staticColumns: ColumnDef<Transaction>[] = [
  {
    id: 'transaction',
    accessorKey: 'transaction',
    header: ({ table }) => (
      <>
        <BulkCheckboxHeader table={table} /> <span>Transactions</span>
      </>
    ),
    cell: ({ row }) => (
      <>
        <BulkCheckboxCell row={row} />
        <TransactionCell />
      </>
    ),
    size: 320,
  },
  // {
  //   id: 'settlementDate',
  //   accessorKey: 'settlementDate',
  //   header: 'Settlement date',
  //   cell: ({ row }) => <DateCell date={row.original.settledAt} />,
  //   size: 148,
  // },
  {
    id: 'bkVerificationStatus',
    accessorKey: 'bkVerificationStatus',
    header: 'Verification',
    cell: ({ row }) => <VerificationStatusCell transaction={row.original} />,
    size: 128,
  },
  {
    id: 'operationDate',
    accessorKey: 'operationDate',
    header: 'Operation date',
    cell: () => <DateCell />,
    size: 128,
  },
  {
    id: 'amount',
    accessorKey: 'amount',
    header: 'Amount',
    cell: () => <AmountCell />,
    size: 128,
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => (
      <StatusCell status={row.original.status} statusHistory={row.original.statusHistory} />
    ),
    size: 128,
  },
  {
    id: 'paymentMethod',
    accessorKey: 'paymentMethod',
    header: 'Payment method',
    cell: () => <PaymentMethodCell />,
    size: 160,
  },
  {
    id: 'reference',
    accessorKey: 'reference',
    header: 'Reference',
    cell: () => <CaptionCell />,
    size: 320,
  },
  {
    id: 'attachments',
    accessorKey: 'attachments',
    header: 'Attachments',
    cell: () => <AttachmentCell />,
    size: 192,
  },
  {
    id: 'membership',
    accessorKey: 'membership',
    header: 'Member',
    cell: ({ row }) => <MembershipCell transaction={row.original} />,
    size: 184,
  },
  {
    id: 'comments',
    accessorKey: 'comments',
    header: 'Comments',
    cell: ({ row }) => <CommentsCell transaction={row.original} />,
    size: 104,
  },
  {
    id: 'category',
    accessorKey: 'category',
    header: 'Category',
    cell: ({ row }) => (
      <CategoryCell
        category={row.original.activityTag?.toLowerCase()}
        transactionId={row.original.id}
      />
    ),
    size: 184,
  },
  // {
  //   id: 'source',
  //   accessorKey: 'source',
  //   header: 'Debited from',
  //   cell: ({ row }) => <SourceCell transaction={row.original} />,
  //   size: 215,
  // },
  // {
  //   id: 'target',
  //   accessorKey: 'target',
  //   header: 'Credited to',
  //   cell: ({ row }) => <TargetCell transaction={row.original} />,
  //   size: 215,
  // },
  // {
  //   id: 'settledBalance',
  //   accessorKey: 'settledBalance',
  //   header: 'Settled balance',
  //   cell: ({ row }) => <AmountCell amount={row.original.settledBalance} />,
  //   size: 200,
  // },
];

import type { ComponentPropsWithRef, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import type { StatusHistoryStatus, StatusHistory } from 'qonto/react/constants';
import { statusHistoryMap } from '../status-map';
import styles from './styles.strict-module.css';

interface StatusCellPopoverProps {
  statusHistory: StatusHistory[];
  currentStatus: ReactNode;
}

export function StatusCellPopover({
  statusHistory,
  currentStatus,
}: StatusCellPopoverProps): ReactNode {
  return (
    <Popover>
      <PopoverHeader>{currentStatus}</PopoverHeader>
      <PopoverSection>
        <header className={styles.title} data-testid="popover-title">
          History
        </header>
        <StatusTimeline statusHistory={statusHistory} />
      </PopoverSection>
    </Popover>
  );
}

export function StatusTimeline({ statusHistory }: { statusHistory: StatusHistory[] }): ReactNode {
  const reversedStatusHistory = statusHistory.toReversed();
  return (
    <ol className={styles.timeline} data-testid="status-timeline">
      {reversedStatusHistory.map((item, index) => {
        return (
          <StatusItem
            data-testid={`item-${item.status.toLowerCase()}-${String(index)}`}
            key={item.status}
            {...item}
          />
        );
      })}
    </ol>
  );
}

interface StatusItemProps extends ComponentPropsWithRef<'li'> {
  status: StatusHistoryStatus;
  createdAt: string;
  'data-testid': string;
}

function StatusItem({ status, createdAt, ...props }: StatusItemProps): ReactNode {
  const { locale } = useIntl();

  const formattedCreatedAt = dateToken({
    date: createdAt,
    locale,
    token: 'date-time-s',
  });

  return (
    <li className={styles['timeline-item']} {...props}>
      <span aria-hidden className={styles['timeline-icon-container']} data-testid="item-icon">
        {statusHistoryMap[status].icon}
      </span>
      <div className={styles['timeline-item-content']} data-testid="item-content">
        <div className={styles['timeline-item-title']} data-testid="item-title">
          {statusHistoryMap[status].title}
        </div>
        <time className={styles['timeline-item-subtitle']} data-testid="item-subtitle">
          {formattedCreatedAt}
        </time>
      </div>
    </li>
  );
}

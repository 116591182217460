import { type ReactNode } from 'react';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status } from 'qonto/react/graphql';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { StopXS } from 'qonto/react/assets/icons/stop-xs';
import styles from './styles.strict-module.css';

export function TransactionCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { counterpartyName: name, slug: id, enrichmentData, status } = transaction;
  const avatar = enrichmentData.logo.small;
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  const subtitle = `⋅⋅ ${id.slice(-14).toUpperCase()}`;
  const icon = avatar ? (
    <StatusAvatar
      alt={name}
      dataTestId="avatar"
      hasBorder
      icon={isDeclined ? <StopXS /> : undefined}
      src={avatar}
    />
  ) : (
    <StatusAvatar
      avatar="custom"
      dataTestId="custom-avatar"
      icon={isDeclined ? <StopXS /> : undefined}
      name={name}
    />
  );

  return (
    <BaseCell>
      <DataWithIconCell
        cellSubtitleClassName={isDeclined ? styles.declined : undefined}
        cellTitleClassName={isDeclined ? styles.declined : undefined}
        icon={icon}
        subtitle={subtitle}
        title={name}
      />
    </BaseCell>
  );
}

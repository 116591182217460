import { useState } from 'react';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type Active,
  type DragEndEvent,
  type SensorDescriptor,
  type SensorOptions,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import type { CashflowCategory } from 'qonto/react/models/cash-flow-category';

type NonNullCategory = CashflowCategory & { id: string };

export function useDragAndDrop(categoriesList: CashflowCategory[] | undefined): {
  categories: NonNullCategory[];
  onDragEnd: (event: DragEndEvent) => { active: Active; updatedCategories: NonNullCategory[] };
  sensors: SensorDescriptor<SensorOptions>[];
} {
  const [categories, setCategories] = useState(filterValidCategoriesWithId(categoriesList));

  const onDragEnd = (
    event: DragEndEvent
  ): { active: Active; updatedCategories: NonNullCategory[] } => {
    const { active, over } = event;
    let updatedCategories: NonNullCategory[] = [];

    if (active.id !== over?.id) {
      const activeIndex = categories.findIndex(item => item.id === active.id);
      const overIndex = categories.findIndex(item => item.id === over?.id);
      const newList = arrayMove(categories, activeIndex, overIndex);
      setCategories(newList);
      updatedCategories = newList;
    }

    return { active, updatedCategories };
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return {
    categories,
    onDragEnd,
    sensors,
  };
}

const filterValidCategoriesWithId = (
  categories: CashflowCategory[] | undefined
): NonNullCategory[] => {
  if (!categories?.length) return [];
  return categories.filter((cat): cat is NonNullCategory => cat.id !== null);
};

import { type ComponentPropsWithRef, type ReactNode } from 'react';
import cx from 'clsx';
import { DialogTrigger, Button } from 'react-aria-components';
import { ChevronBottomOutlined } from 'qonto/react/assets/icons';
import { CellAction } from 'qonto/react/components/buttons';
import { ActionSlot } from '../../action-slot';
import styles from './styles.strict-module.css';

interface BaseCellProps extends ComponentPropsWithRef<'div'> {
  align?: 'left' | 'right';
  actionSlot?: ReactNode;
  popoverSlot?: ReactNode;
  disableHoverAction?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  'data-testid'?: string;
  className?: string;
}

export function BaseCell({
  children,
  actionSlot,
  popoverSlot,
  align = 'left',
  disableHoverAction,
  onOpenChange,
  className,
  ...props
}: BaseCellProps): ReactNode {
  const hasPopoverSlot = Boolean(popoverSlot);
  const hasActionSlot = Boolean(actionSlot);

  const popoverTrigger = (
    <Button className={styles['cell-popover-trigger']} data-testid="popover-trigger">
      {children}
      <div className={cx(styles['cell-actions'], disableHoverAction && styles['hover-disabled'])}>
        <CellAction aria-hidden="true">
          <ChevronBottomOutlined data-testid="chevron-bottom" />
        </CellAction>
      </div>
      {popoverSlot}
    </Button>
  );

  const cellContent = (
    <div {...props} className={cx(styles.cell, styles[`align-${align}`], className)}>
      {hasPopoverSlot ? popoverTrigger : children}
      {hasActionSlot ? (
        <ActionSlot className={styles['cell-actions']}>{actionSlot}</ActionSlot>
      ) : null}
    </div>
  );

  if (hasPopoverSlot) {
    return <DialogTrigger onOpenChange={onOpenChange}>{cellContent}</DialogTrigger>;
  }

  return cellContent;
}

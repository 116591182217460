import { type ReactNode } from 'react';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import type { LabelList } from 'qonto/react/models/label';
import { BulkTransactionsProvider } from 'qonto/react/contexts/bulk-transactions-context';
import { SidePanel } from '../side-panel';
import { Header } from './components/header';
import styles from './styles.strict-module.css';
import { LabelsSection } from './components/section/labels';
import { Footer } from './components/footer';
import { VerificationStatusSection } from './components/section/verification-status';
import { CategorySection } from './components/section/category';
import { AttachmentSection } from './components/section/attachment';

interface BulkSidePanelProps {
  labelLists: LabelList[];
  organizationId: string;
}

export function BulkSidePanel({ labelLists, organizationId }: BulkSidePanelProps): ReactNode {
  const { selection, setShouldResetSelection } = bulkSelectionManager.useBulkSelection();
  const numberOfTransactions = selection.length;

  const handleClose = (): void => {
    setShouldResetSelection(true);
  };

  return (
    <SidePanel data-testid="bulk-side-panel" isVisible={numberOfTransactions > 0}>
      <BulkTransactionsProvider organizationId={organizationId}>
        <div className={styles.container}>
          <Header numberOfTransactions={numberOfTransactions} onClose={handleClose} />
          <div className={styles.body}>
            <CategorySection />
            <LabelsSection labelLists={labelLists} />
            <AttachmentSection />
            <VerificationStatusSection />
          </div>
          <Footer onClose={handleClose} />
        </div>
      </BulkTransactionsProvider>
    </SidePanel>
  );
}

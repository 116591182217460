import { useIntl } from 'react-intl';
import { type ReactNode } from 'react';
import cx from 'clsx';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { STEPS } from 'qonto/constants/international-out/lifecycle';
import type { ProcessingEvent } from 'qonto/services/international-out/types';
import styles from './styles.strict-module.css';

interface EventProps {
  event: Pick<ProcessingEvent, 'name' | 'date'>;
}

export function Event({ event: { name, date } }: EventProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const getDescription = (): string | null => {
    switch (name) {
      case STEPS.CREATION:
        return formatMessage({ id: 'international-out.timeline.step.creation' });
      case STEPS.VALIDATION:
        return formatMessage({ id: 'international-out.timeline.step.validation' });
      case STEPS.SHIPMENT:
        return formatMessage({ id: 'international-out.timeline.step.shipment' });
      case STEPS.COMPLETION:
        return formatMessage({ id: 'international-out.timeline.step.completion' });
      default:
        return null;
    }
  };

  const description = getDescription();

  return (
    <div className={styles.step} data-testid="event">
      {date ? (
        <p className={cx('caption', styles.caption)} data-testid="date">
          {dateToken({
            date,
            locale,
            token: 'date-time-s',
          })}
        </p>
      ) : null}
      {description ? (
        <p className={cx('body-2', styles.description)} data-testid="description">
          {description}
        </p>
      ) : null}
    </div>
  );
}

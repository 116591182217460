import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useCashflowTimeseries } from 'qonto/react/hooks/use-cashflow-timeseries';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import { type CashflowPeriod } from 'qonto/react/models/cash-flow-period';
import { getPeriodTimeframeInterval } from 'qonto/react/utils/cash-flow-timeseries/period-timeframe-interval';
import type { LabelStatisticsResponse, UnlabeledStatisticsResponse } from '../../api/labels';
import { BalanceTable } from '../balance-table';
import { useLabelCashflows } from '../../hooks/use-label-cashflows';
import { LabelsTable } from '../labels-table';
import { CategoriesTable } from '../categories-table/categories-table';
import { useTimeseriesBalances } from '../../hooks/use-timeseries-balances';
import { useCategoriesCashflows } from '../../hooks/use-categories-cashflow';
import styles from './styles.strict-module.css';

interface TablesProps {
  organizationId: string;
  bankAccounts: string;
  selectedPeriod: CashflowPeriod;
  labelStatistics: LabelStatisticsResponse;
  unlabeledStatistics: UnlabeledStatisticsResponse;
  offset?: number;
  displayedMonths?: number;
  isLoading?: boolean;
  isUpdating?: boolean;
  isError?: boolean;
}

export function TablesLayout({
  organizationId,
  bankAccounts,
  selectedPeriod,
  labelStatistics,
  unlabeledStatistics,
  offset = 0,
  displayedMonths = 6,
  isLoading = false,
  isUpdating = false,
}: TablesProps): ReactNode {
  const abilities = useEmberService('abilities');
  const canAssignCategory = abilities.can('assign category');

  const { data: labelLists, isLoading: isLoadingLabelLists } = useFetchLabelLists(organizationId);
  const labelTableData = useLabelCashflows(
    offset,
    displayedMonths,
    labelStatistics,
    unlabeledStatistics,
    labelLists
  );

  const timeframe = getPeriodTimeframeInterval(selectedPeriod);
  const queryKey = `${organizationId}-${timeframe.start_year_month}-${timeframe.end_year_month}-${bankAccounts}`;

  const { data: timeseries, isPending: isLoadingTimeseries } = useCashflowTimeseries(
    {
      bank_account_ids: bankAccounts ? [bankAccounts] : undefined,
      ...timeframe,
    },
    queryKey
  );

  const { inflows, inflowSums, outflows, outflowSums } = useCategoriesCashflows(
    offset,
    displayedMonths,
    timeseries
  );

  const { startBalances, endBalances } = useTimeseriesBalances(offset, displayedMonths, timeseries);

  return (
    <section className={styles.wrapper} data-testid="tables-layout">
      <BalanceTable
        data={startBalances}
        data-testid="start-balance-table"
        isLoading={isLoading || isUpdating}
        numberOfColumns={displayedMonths}
        type="start"
      />

      {canAssignCategory ? (
        <>
          <CategoriesTable
            data={inflows}
            data-testid="inflows-table"
            headerLabelKey="cash-flow-categories.group-title.inflows"
            isLoading={isLoadingTimeseries || isLoading || isUpdating}
            numberOfColumns={displayedMonths}
            sums={inflowSums}
          />

          <CategoriesTable
            data={outflows}
            data-testid="outflows-table"
            headerLabelKey="cash-flow-categories.group-title.outflows"
            isLoading={isLoadingTimeseries || isLoading || isUpdating}
            numberOfColumns={displayedMonths}
            sums={outflowSums}
          />
        </>
      ) : (
        <LabelsTable
          data={labelTableData}
          data-testid="label-table"
          isLoading={isLoading || isLoadingLabelLists}
          numberOfColumns={displayedMonths}
        />
      )}

      <BalanceTable
        data={endBalances}
        data-testid="end-balance-table"
        isLoading={isLoading || isUpdating}
        numberOfColumns={displayedMonths}
        type="end"
      />
    </section>
  );
}
